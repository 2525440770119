@import '../../styles/customMediaQueries.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    width: 100%;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.error {
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.heading {
  hyphens: auto;
}

.stripeConnectAccountForm {
  margin-top: 0;
}

.inputWrapper {
  margin-bottom: 30px;
}